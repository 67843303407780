.base {
    padding-top: 126px;
    padding-left: 380px;
    padding-bottom: 100px;
    background-color: rgb(231, 231, 231);
}

.all-project {
    display: block;
    justify-content: space-between;
}

.all-project-style{
    float: right;
    padding: 10px;
    width: 30%;
    background: rgb(240, 240, 240);
    margin-left: 366px;
    margin-right: 618px;
    margin-top: 80px;
}

.container {
    border: 2px solid lightgray;
    border-radius: .5em;
    background-color: white;
    width: 90%;
    margin: 0em auto;
    display: flex;
    overflow: hidden;
    max-width: 885px;
    float: right;
    margin-right: 50px!important;
}
  
.container .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
 }
 
.container .content {
    display: inline-flex;
}

.container img {
    max-width: 400px;
    max-height: 400px;
    border: 2px solid #f9f9f9;
    border-radius: .5em;
    margin-top: 50px;
    margin-left: 30px;
    position: relative;
    float: left;
}

.container .condo-list1 {
    list-style-type: none;
    margin-top: 10px;
    margin-left: 50px;
}

.container .condo-list2 {
    list-style-type: none;
    display: inline-block;
    margin-right: 165px;
}

.container .condo-list3 {
    list-style-type: none;
    display: inline-block;
}

.container .condo-list4 {
    list-style-type: none;
    display: inline-block;
    margin-right: 120px;

}

.container .condo-list5 {
    list-style-type: none;
    display: inline-block;

}

.container .condo-name {
    display: block;
    font-size: 40px;
    font-weight: bold;
}

.container .condo-address {
    display: block;
    font-size: 20px;
    font-weight: 500;
}

.container .condo-detail-top {
    display: block;
    color: gray;
    font-size: 15px;
    font-weight: bold;
}

.container .condo-detail-bottom {
    display: block;
    font-size: 20px;
    font-weight: 500;
}

.container .card {
    margin-left: 30px;
    border: 0px solid rgba(0,0,0,.125);
}

.container .card-header {
    background-color: rgba(0,0,0,0);
}

.container .accordion-title {
    cursor: context-menu;
    user-select:none;
}

.container .box {
    border-radius: .5em;
    background-color: rgb(231, 231, 231);
    width: 14%;
    margin: 0em auto;
    display: inline-block;
    overflow: hidden;
    max-width: 250px;
    margin-right: 20px;
    margin-top: 20px;
}

.container .box-number {
    font-size: 50px;
    text-align: center;
    background-color: rgb(231, 231, 231);
    margin-bottom: auto;
}

.container .box-text {
    font-size: 17px;
    font: bold;
    text-align: center;
    background-color: rgb(231, 231, 231);
    padding: 10px;
}

.container .table-title {
    margin-top: 40px;
    margin-bottom: 40px;

}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0;
}

hr.new1 {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 30px;
    padding: 0;
}