.savebtn {
  height: 40px;
  width: 190px;
  border-radius: 4px;
  background-color: #1176b5;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d;
}

.radioInput {
  margin-right: 100px;
}
