.projects-list-title {
  margin: 64px 0px 0px 130px;
  color: #05314d;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
}

.project-list-nav {
  margin: 31px 130px 0px 130px;
}

.project-list-nav .project-list-link {
  padding: 0px 0px 11px 0px !important;
  margin-right: 32px;
  color: #1176b5;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.project-list-nav .project-list-link.active {
  padding: 0px 0px 11px 0px !important;
  color: #2e2e2e !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  background-color: transparent !important;
  border-bottom: 4px solid #ffb400;
  border-radius: 0px;
}

.project-list-nav .download-xlsx {
  height: auto;
  width: auto;
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
}

.project-list-nav .download-xlsx:hover {
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
}

.project-list-nav .download-xlsx:focus {
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  outline-offset: none !important;
}

.project-list-nav .filter-container {
  height: 24px;
  width: 95px;
  padding-top: 10px;
  margin-right: 32px;
  cursor: pointer;
}

.project-list-nav .filter-container .filter-button {
  height: 24px;
  width: 130px;
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.project-list-nav .filter-container .filter-icon {
  color: #1176b5;
  margin-right: 8px;
}

.card-container .projects-container {
  height: auto;
  width: 1180px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 39px;
  margin-right: 39px;
  margin-top: 26px;
  padding-top: 46px;
}

.projects-content {
  margin: 0px 130px 0px 130px;
  padding-bottom: 300px;
}

.projects-container .list-filters {
  display: flex;
  flex-direction: row;
  height: 48px;
  width: auto;
  background-color: rgba(0, 0, 0, 0.03);
  padding-top: 12px;
}

.projects-container .list-filters .filter-by-condo {
  height: 24px;
  width: 16%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 51px;
}

.projects-container .list-filters .filter-by-status {
  height: 24px;
  width: 12%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.projects-container .list-filters .filter-by-address {
  height: 24px;
  width: 18%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.projects-container .list-filters .filter-by-city {
  height: 24px;
  width: 18%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.projects-container .list-filters .filter-by-state {
  height: 24px;
  width: 11%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.projects-container .list-filters .filter-by-projectid {
  height: 24px;
  width: 11%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.projects-container .list-filters .filter-by-hoaid {
  height: 24px;
  width: 10%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.projects-container .list-filters .sort-icon {
  height: 14px;
  width: 10px;
  color: #0d6da9;
  margin-left: 8px;
}

.projects-footer {
  height: 64px;
  width: auto;
  border: 1px solid #dee1e2;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
