.condition-text {
  padding: 0px 16px;
  display: flex;
}

.condition-text .condition-image {
  float: left;
}

.condition-text .text {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 10px;
}
