.card-display {
    display: flex!important;
    column-gap: 70px;
    flex-flow: wrap;
}

.individual-card {
    height: auto;
    width: 321px; 
    margin-right: auto;
    margin-bottom: 70px;
}

.individual-card .card-image{
    height: 181px;
    width: auto;
}

.individual-card .card-body {
    height: auto;
    flex: auto;
    padding: 9px 16px 0px 16px;
    min-height: 0px;
}

.individual-card .card-body .card-left-body {
    height: auto;
    width: 160px;
    float: left;
    user-select: all;

}

.grid-container{
    display: grid;
    /* grid-template-rows: auto auto auto auto; */
    grid-template-rows: auto auto auto;
    grid-column-gap: 10px;
}

.grid-item{
    font-size: 16px;
}

.individual-card .card-project-name{
    height: auto;
    width: 280px;
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 6px;
    grid-column: 1/4;
    user-select:all;
}

.individual-card .card-street-name{
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    max-height: 200px;
    line-height: 19px;
    margin-bottom: 4px;
    grid-column: 1/3;
    grid-row: 2;
    user-select:all;
}

.individual-card .card-city-name{
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    grid-column: 1/3;
    grid-row: 3;
    user-select: all;
}

.individual-card .card-body .card-right-body {
    height: auto;
    width: auto;
    float: right;
}

.individual-card .card-body .card-right-body .card-eligibility {
    height: auto;
    width: auto;
    color: #0CA024;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 15px;
    box-sizing: border-box;
    border: 1px solid #0CA024;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 0px;
}

.individual-card .card-project-id-text {
    height: auto;
    width: auto;
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 27px;
    text-align: right;
    margin-bottom: -9px;
    margin-top: 10px;
    grid-column: 4/1;
    grid-row: 2;
    user-select:all;
}

.individual-card .card-project-id {
    height: auto;
    width: auto;
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 27px;
    text-align: right;
    grid-column: 4/1;
    grid-row: 3;
    user-select:all;
}

.card-text{
    padding-left: 0;
}
            
            