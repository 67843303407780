.certification-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "side main main main";
}

.certification-container .side {
  grid-area: side;
  background-color: #f2f2f2;
}

.certification-container .side .sticky-stepper {
  position: sticky;
  top: 0;
}

.certification-container .side .side-container {
  background-color: #f2f2f2;
  margin: 62px 59px 0px 104px;
}

.certification-container .side .side-container .step-label {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600 !important;
}

.certification-container
  .side
  .side-container
  .MuiStepLabel-label.MuiStepLabel-active {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

.certification-container .side .side-container .MuiStepLabel-label {
  color: rgba(0, 0, 0, 0.54);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.certification-container .main {
  grid-area: main;
  background-color: #f8f8f8;
  padding: 49px 145px 100px 106px;
}

.certification-container .main .title {
  color: #05314d;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  padding-bottom: 8px;
}

.certification-container .main .subtitle {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 30px;
}

.certification-container .main .decline-info {
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-rows: auto;
  grid-template-areas: "left right";
  border: 1px solid #d02f2f;
  border-radius: 4px;
  margin-top: 15.5px;
}

.certification-container .main .decline-info .decline-info-symbol {
  grid-area: left;
  border-radius: 4px 0 0 4px;
  background-color: #d02f2f;
  margin-bottom: -1px;
  text-align: center;
}

.certification-container .main .decline-info .decline-info-text {
  grid-area: right;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  padding-top: 5px;
  padding-left: 24px;
}

.certification-container .main .info {
  box-sizing: border-box;
  height: auto;
  width: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: #f2f2f2;
  margin-top: 15.5px;
}

.certification-container .main .info .info-container {
  display: flex;
  flex-direction: column;
  padding: 16.5px 32.5px;
}

.certification-container .main .info .info-container .type {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.certification-container .main .info .info-container .attributes {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.certification-container .main .form {
  box-sizing: border-box;
  height: auto;
  width: auto;
  background-color: #ffffff;
  margin-top: 16px;
}

.certification-container .main .form .form-header {
  box-sizing: border-box;
  height: auto;
  width: auto;
  border-bottom: 1px solid #e8e8e8;
  background-color: #ffffff;
  padding: 31px 0px 19.86px 35px;
}

.certification-container .main .form .form-header .subheader {
  color: rgba(0, 0, 0, 0.4);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.certification-container .main .form .form-header .title {
  color: #000f2b;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  padding-bottom: 0px;
}

.certification-container .main .form .form-header .subtitle {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.certification-container .main .form .form-header .decline-symbol {
  text-align: center;
  padding-bottom: 9px;
}

.certification-container .main .form .form-header .decline-title {
  color: #05314d;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
}

.certification-container .main .form .form-header .decline-subtitle {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  padding-top: 16px;
}

.certification-container .main .form .form-body {
  padding: 24.28px 34px 16px 35px;
}

.certification-container
  .main
  .form
  .form-body
  .certification-result-restriction-container {
  box-sizing: border-box;
  border: 1px solid #d02f2f;
  border-radius: 4px;
  background-color: #fefbfb;
  box-shadow: 0 0.1px 0.2px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 16px;
}

.certification-container
  .main
  .form
  .form-body
  .certification-result-restriction-container
  .restriction-title {
  color: #d02f2f;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10px;
}

.certification-container
  .main
  .form
  .form-body
  .certification-result-restriction-container
  .restriction-attribute {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
}

.certification-container .main .form .form-body .additional-q-container {
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  padding: 16px 18px;
  margin: 0px 21px 24px 21px;
}

.certification-container .main .form .form-body .additional-q-container .title {
  color: #000f2b;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
}

.certification-container
  .main
  .form
  .form-body
  .additional-q-container
  .subtitle {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 10px;
  padding-bottom: 25px;
}

.certification-container .main .form .form-body .form-title {
  padding-bottom: 13px;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.certification-container .main .form .form-body .form-subtitle {
  padding-bottom: 13px;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.certification-container .main .form .form-body .question {
  padding-left: 12px;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
}

.certification-container .main .form .form-body .summary-answer {
  padding: 0px 48px 20px 48px;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
}

.certification-container .main .form .form-body .summary-answer .label {
  color: #949494;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.certification-container .main .form .form-body .summary-answer .attribute {
  font-weight: bold;
}

.certification-container .main .form .form-body .question .number {
  width: auto;
  min-width: 19.9px;
  margin-right: 15px;
}

.certification-container .main .form .form-body .answer {
  padding: 8px 48px;
}

.certification-container .main .form .form-body .answer .convey-calc-btn {
  padding: 0px 16px;
  margin: 0px 24px 0px 24px;
  border-radius: 4px;
  background-color: #0c77ba;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: unset;
  max-height: 40px;
  text-transform: none;
  font-weight: unset;
}

.certification-container
  .main
  .form
  .form-body
  .answer
  .convey-calc-btn:disabled {
  padding: 0px 16px;
  margin: 0px 24px 0px 24px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.26);
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: unset;
  max-height: 40px;
  text-transform: none;
  font-weight: unset;
}

.certification-container .main .form .form-body .answer .convey-result {
  padding: 8px 16px;
  box-sizing: border-box;
  border: 1px solid #ffe385;
  border-radius: 4px;
  background-color: #fff2c7;
  color: rgba(0, 0, 0, 0.4);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.certification-container .main .form .form-body .answer .year-dropdown {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 57px 8px 15px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.certification-container .main .form .form-body .form-error {
  color: #f44336;
  font-size: 0.75rem;
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  line-height: 2.7;
  letter-spacing: 0.03333em;
}

.certification-container
  .main
  .form
  .form-body
  .answer
  .MuiFormHelperText-root.Mui-error {
  margin: 0px;
  padding: 0px 14px;
  background-color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
}

.certification-container .main .form .form-body .answer .r-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 110px;
}

.certification-container .main .form .form-body .answer .r-container .label {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.certification-container
  .main
  .form
  .form-body
  .answer
  .r-container
  .label
  .alignment {
  margin-top: 0px;
  margin-right: 3px;
  height: 100%;
  vertical-align: top;
}

.certification-container .main .form .form-footer {
  padding: 24.28px 33px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
}

.certification-container .main .form .form-footer .next-button {
  padding: 5.25px 16.75px !important;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.3),
    0 0.5px 0 1px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: unset;
}

.certification-container .main .form .form-footer .save-exit-btn {
  padding: 5.25px 16.75px;
  background-color: transparent;
  border: unset;
  box-shadow: unset;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.certification-container .main .form .form-footer .complete-cert-btn {
  padding: 8px 16.75px !important;
  border-radius: 4px;
  background-color: #1176b5;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: unset;
}

.certification-container .main .form .form-footer .exit-cert-btn {
  padding: 8px 24px !important;
  border-radius: 4px;
  background-color: #1176b5;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: unset;
}

.certification-container .main .form .form-body .user-agreement {
  border-top: 1px solid #e8e8e8;
  margin-top: 32px;
}

.certification-container .main .form .form-body .user-agreement .title {
  color: #000f2b;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  margin-top: 24px;
  margin-bottom: 20px;
}

.certification-container
  .main
  .form
  .form-body
  .user-agreement
  .disclaimer-container {
  padding: 8px 17px;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: inset 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.1),
    inset 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.1);
}

.certification-container .main .form .form-body .user-agreement .info-type {
  color: #000f2b;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.recertify-button {
  padding: 8px 16.75px !important;
  border-radius: 4px;
  background-color: #1176b5;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: unset;
}

.user-information{
  text-align: center;
  height: fit-content;
  margin-bottom: 0 !important;
  width: 100% !important;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contact-phone{
  grid-row: 1;
  grid-column: 1;
  font-weight: bold;
}
.user-phone{
  grid-row: 2;
  grid-column: 1;
  
}
.contact-email{
  grid-row: 1;
  grid-column: 2;
  font-weight: bold;
}
.user-email{
  grid-row: 2;
  grid-column: 2;
}

.contact-id{
  grid-row: 1;
  grid-column: 3;
  font-weight: bold;
}

.user-id{
  grid-row: 2;
  grid-column: 3;
}

.contact-lender{
  grid-row: 1;
  grid-column: 4;
  font-weight: bold;
}

.user-lender{
  grid-row: 2;
  grid-column: 4;
}

