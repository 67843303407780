.search-icon {
    height: 50px !important;
    width: 40px !important;
    color: #FFFFFF;
}

.autoCompleteSuggestionsContainer {
    display: flex;
    flex-direction: row;
    margin: 0px 20px;
}

.suggestionWrapper {
    position: absolute;
    top: 49px;
    width: 579px;
}

.searchButtonWrapper {
    position: relative
}

.liContainer {
    height: 51px;
    width: auto;
    display: inline-flex;
}
.liContainer:hover {
    height: 51px;
    width: auto;
    background-color: rgba(8,82,128,0.1);
}

.autocompleteText {
    display: flex;
    flex-direction: row;
    height: 23px;
    color: #032033;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    margin-left: 10px;
    text-transform: capitalize ;
    font-weight: bold;
    letter-spacing: -0.56px;
    line-height: 23px;
}
.ulContainer {
    list-style-type: none;
    box-sizing: border-box;
    height: auto;
    width: auto;
    margin-top: 4px;
    padding: 25px 0px;
    position: absolute;
    z-index: 9999;
    border: 0.5px solid rgba(151,151,151,0.5);
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    
}

.autocomplete-search-icon {
    height: 21px;
    width: 23px;
}

.suggestionWrapper2 {
    /* position: absolute;
    top: 49px;
    width: 579px; */
}

.liContainer2 {
    height: auto;
    width: auto;
    max-width: 270px;
    min-width: 193px;
    padding: 10px 0px;
    display: list-item;
}
.liContainer2:hover {
    height: auto;
    width: auto;
    max-width: 270px;
    min-width: 193px;
    padding: 10px 0px;
    display: list-item;
    background-color: rgba(8,82,128,0.1);
}
.autocompleteContainer2 {
    height: auto;
    width: auto;
}
.autocompleteText2 {
    display: flex;
    flex-direction: row;
    color: #032033;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    margin-left: 10px;
    text-transform: capitalize ;
    letter-spacing: -0.56px;
    line-height: 23px;
}
.ulContainer2 {
    list-style-type: none;
    box-sizing: border-box;
    height: 250px;
    width: auto;
    margin-top: 4px;
    padding: 25px 0px;
    position: absolute;
    z-index: 9999;
    border: 0.5px solid rgba(151,151,151,0.5);
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    overflow: auto;
    
}