.help-banner{
    background-image: linear-gradient(312.8deg, rgba(12,123,145,0.2) 0%, rgba(8,82,128,0.8) 100%), url('../../images/GettyImages-1186126093.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 300px;
}
.help-body{
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 0px;
} 
.help-center{
    height: 60px;
    width: 413px;
    color: #FFFFFF;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0.14px;
    line-height: 60px;
    padding-left: 110px;
    padding-top: 70px;
}
.have-a-question-we{
    height: 36px;
    color: #FFFFFF;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 36px;
    padding-left:110px;
    padding-top: 45px;
    
  }
.help-body-header{
    color:#3e6f9a;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5em;
    padding-left: 20px;
}
.help-body-content{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.125em!important;
    color: black;
    padding-left: 20px!important;
    padding-right: 15px!important;
}
.help-card{
    min-width: 500px;
    width: 30%;
    min-height: 300px;
    height: 100%;
    box-shadow: 0px 3px 3px 3px #bebdbd;
    margin-right: 32px;
    margin-bottom: 32px;
    
}

.card-text-body{
    min-height: 60px;
    height: 100%;
    font-size: 18px;
}
.line{
    border-top: 1px solid black;
    width:95%
}
.card-button{
    margin: 20px;
    width: 26% /*140px*/;
    /*height: 40px;*/
    min-height: 40px;
    border-radius: 4px;
    background-color: #FCFCFC;
    border: none;
    box-shadow: 0 1px 0.8px 1px rgba(0,0,0,0.3), 0 0.5px 0 1px rgba(0,0,0,0.2);
    cursor: not-allowed;
    pointer-events: all !important;
    font-size: 1em;
}

.getting-started-help-body-header{
    color:#3e6f9a;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 32pt;
    font-weight: 600;
}

.getting-started-help-body-content{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px;
    color: black;
    margin-bottom: 8px;
}

.card {
    display: inline-flex!important;
}



