.savebtn {
  height: 40px;
  width: 190px;
  border-radius: 4px;
  background-color: #1176b5;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d;
}

.radioInput {
  margin-right: 100px;
}

.neutral-modal-button{
  text-transform: none!important;
  background-color: #0c77ba!important;
  color: #F2F2F2!important;
  padding: 8px 17px!important;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}