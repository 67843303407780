.list-card-display {
  display: contents !important;
}

.list-card-row {
  position: unset;
  height: 85px;
  width: -webkit-fill-available;
  border: 1px solid #dee1e2 !important;
  background-color: #ffffff !important;
  border-radius: 0px !important;
  margin-bottom: -7px;
  margin-right: 0px;
}

.list-card-row .list-card-body {
  min-height: 85px;
  width: 100%;
  flex: auto;
  float: left;
  padding: 0px;
  position: unset;
  left: 146px;
  display: flex;
}

.list-card-row .list-card-body .list-card-project-name {
  max-height: 85px;
  max-width: 14%;
  width: 14%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 51px;
  padding-top: 32px;
}

.list-card-row .list-card-body .list-card-status {
  max-height: 85px;
  max-width: 10%;
  width: 10%;
  color: #0ca024;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 33px;
  padding-top: 32px;
}
.list-card-row .list-card-body .list-card-address {
  max-height: 85px;
  max-width: 18%;
  width: 18%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 28px;
  padding-top: 32px;
}
.list-card-row .list-card-body .list-card-city {
  max-height: 85px;
  max-width: 18%;
  width: 18%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 27px;
  padding-top: 32px;
}
.list-card-row .list-card-body .list-card-state {
  max-height: 85px;
  max-width: 8%;
  width: 8%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 16px;
  padding-top: 32px;
}
.list-card-row .list-card-body .list-card-projectid {
  max-height: 85px;
  max-width: 10%;
  width: 10%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 48px;
  padding-top: 32px;
}
.list-card-row .list-card-body .list-card-hoaid {
  max-height: 85px;
  max-width: 10%;
  width: 10%;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 33px;
  padding-top: 32px;
}
