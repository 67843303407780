.navbar-top {
  width: auto;
  height: 32px;
  background-color: #032033;
  border-bottom: 0px;
}

.navbar-bottom {
  height: 61px;
  width: auto;
  background-color: #085280;
  border-bottom: 0px;
}

.navbar-top .settings-button {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: auto !important;
  margin-right: 0px !important;
}

.navbar-top .settings-icon {
  height: auto;
  margin-right: 4px !important;
  margin-bottom: 2px;
}

.navbar-top .lender {
  justify-content: flex-end;
  display: flex;
  width: 50%;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

.navbar-top .lender-drop-down {
  padding-left: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

.navbar-top .user-profile-lender-view {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.navbar-top .user-profile {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  margin-left: 10px !important;
  margin-right: 0px !important;

  justify-content: flex-end;
  display: flex;
  width: 90%;
}

.user-role {
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  margin-left: 10px !important;
  margin-right: 10px !important;
  justify-content: flex-end;
  display: flex;
  width: 75%;
  height: auto;
}

.navbar-top .user-role {
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  width: auto;
  margin-left: 15px;
  padding: 0 !important;
  white-space: nowrap;
}

.navbar-top .user-icon {
  height: auto;
  width: 14px;
  margin-right: 4px !important;
  margin-bottom: 3px;
}

.navbar-bottom .brand-logo {
  margin-right: 16px;
}
.move-left {
  margin-left: 32px;
}
.move-right {
  margin-right: 32px;
}

.navbar-bottom .brand-button {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px;
  border-left: 2px solid;
  border-left-color: #ffffff;
  padding-left: 16px;
}

.navbar-bottom .dashboard-button {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: auto !important;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-bottom .dashboard-button--active {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: auto !important;
  border-bottom: 4px solid;
  border-bottom-color: #ffb400;
  padding-bottom: 14px;
  padding-top: 19px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}

.navbar-bottom .all-projects-button {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 10px !important;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-bottom .all-projects-button--active {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 10px !important;
  border-bottom: 4px solid;
  border-bottom-color: #ffb400;
  padding-bottom: 14px;
  padding-top: 19px;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-bottom .eligibility-list-button {
  height: auto;
  width: 107px;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 10px !important;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-bottom .eligibility-list-button--active {
  height: auto;
  width: 107px;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 10px !important;
  border-bottom: 4px solid;
  border-bottom-color: #ffb400;
  padding-bottom: 14px;
  padding-top: 19px;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-bottom .help-button {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  /* margin-left: auto !important; */
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.navbar-bottom .help-button--active {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 10px !important;
  border-bottom: 4px solid;
  border-bottom-color: #ffb400;
  padding-bottom: 14px;
  padding-top: 19px;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-bottom .add-project-list-button {
  height: auto;
  width: 146px;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-bottom .add-project-list-button--active {
  height: auto;
  width: 146px;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  border-bottom: 4px solid;
  border-bottom-color: #ffb400;
  padding-bottom: 14px;
  padding-top: 19px;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-bottom .add-new-project-button {
  box-sizing: border-box;
  height: 40px;
  width: 168px;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 4px;
  background-color: #ffffff;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 32px !important;
  margin-right: 32px !important;
  padding: 0px;
  text-transform: none;
  font-weight: normal;
}

.navbar-bottom .add-new-project-button:focus {
  outline: none;
}

.navbar-bottom .add-new-project-button:hover {
  text-decoration: none;
  background-color: #ffffff;
}

.nav-container {
  justify-content: unset !important;
  width: unset !important;
  margin-right: unset !important;
}
.navbar-bottom .reports-button {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  /* margin-left: auto !important; */
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.navbar-bottom .reports-button--active {
  height: auto;
  width: auto;
  color: #ffffff !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 10px !important;
  border-bottom: 4px solid;
  border-bottom-color: #ffb400;
  padding-bottom: 14px;
  padding-top: 19px;
  padding-left: 10px;
  padding-right: 10px;
}

.logout-button{
  background-color: green;
}