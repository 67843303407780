
.card-display {
    display: contents;
}

.card-row {
    height: 85px;
    width: 1103px;
    border: 0px!important;
    margin-bottom: 16px;
}

.card-row .card-image {
    height: 85px;
    width: 146px!important;
    float: left
}
.image {
    height: 85px;
    width: 146px!important;
    float: left
}
.sort {
    height: 14px;
    width: 10px;
    color: #0d6da9;
    margin-left: 8px;
}
.header{
    height: 48px;
    width: 1103px;
    background-color: rgba(0,0,0,.03);
    padding-top: 12px;
}
.headerstyle {
    color: #000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
}
.imgbox {
    padding: 0px 10px 10px 0px;
    border-bottom: none;
}
.pname {
    padding: 0px 10px 10px 0px;
    border-bottom: none;
}
.status {
    padding: 0px 10px 10px 0px;
    border-bottom: none;
}
.street {
    padding: 0px 10px 10px 0px;
    border-bottom: none;
}
.city {
    padding: 0px 10px 10px 0px;
    border-bottom: none;
}
.state {
    padding: 0px 10px 10px 0px;
    border-bottom: none;
}
.pid{
    padding: 0px 10px 10px 0px;
    border-bottom: none;
}
.card-row .card-body {
    min-height: 85px;
    width: 956px;
    flex: auto;
    float: left;
    padding: 0px;
    position: absolute;
    left: 146px;
    display: flex;
}

.card-row .card-body .card-project-name {
    max-height: 85px;
    max-width: 117px;
    width: 117px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    overflow: hidden;
    margin-left: 22px;
    padding-top: 32px;
}

.card-row .card-body .card-status{
    max-height: 85px;
    max-width: 88px;
    width: 88px;
    color: #0CA024;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    overflow: hidden;
    margin-left: 33px;
    padding-top: 32px;
}
.card-row .card-body .card-address {
    max-height: 85px;
    max-width: 160px;
    width: 160px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    overflow: hidden;
    margin-left: 28px;
    padding-top: 32px;
}
.card-row .card-body .card-city {
    max-height: 85px;
    max-width: 124px;
    width: 124px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    overflow: hidden;
    margin-left: 27px;
    padding-top: 32px;
}
.card-row .card-body .card-state {
    max-height: 85px;
    max-width: 40px;
    width: 40px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    overflow: hidden;
    margin-left: 16px;
    padding-top: 32px;
}
.card-row .card-body .card-projectid {
    max-height: 85px;
    max-width: 100px;
    width: 100px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    overflow: hidden;
    margin-left: 48px;
    padding-top: 32px;
}
.card-row .card-body .card-hoaid {
    max-height: 85px;
    max-width: 105px;
    width: 105px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    overflow: hidden;
    margin-left: 33px;
    padding-top: 32px;
}


