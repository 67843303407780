.field-info-container {
    margin-left: 42px;
    margin-bottom: 11px;
}

.radiobtn-letter-error {
    color: red;
    font-size: 14px;
    margin-left: 42px;

}

.letter-checkbox-container {
    margin-left: 42px;
}

.letter-accordion-body-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: 
        "left right"
}

.letter-accordion-body-container .left {
    grid-area: left;
    padding-top: 10px;
    padding-left: 10px;
}

.letter-accordion-body-container .right {
    grid-area: right;
    padding-top: 10px;
}

.letter-accordion-body-container .left .label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 1px;
}

.letter-accordion-body-container .left .value {
    font-size: 14px;
    padding-bottom: 8px;
    margin-bottom: 0;
}

.letter-accordion-body-container .right .label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 1px;
}

.letter-accordion-body-container .right .value {
    font-size: 14px;
    padding-bottom: 8px;
    margin-bottom: 0;
}