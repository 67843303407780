.accordion-body-card-attributes {
  padding: 0px 0px 35px 0px;
  border-bottom: 1px solid #EDEBE9;
  margin-bottom: 13px;
}

.accordion-body-card-attributes .accordion-body-card-attributes-status {
  color: #5ACA49;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  float: left;
  font-weight: bold;
  padding-right: 25px;
}

.accordion-body-card-attributes .accordion-body-card-attributes-name {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  float: left;
  padding-right: 25px;
}

.accordion-body-card-attributes .accordion-body-card-attributes-semicolon {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  float: right;
  padding-right: 25px;
}

.accordion-body-card-attributes .accordion-body-card-attributes-value {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  font-weight: bold;
  float: right;
  width: 100%;
}