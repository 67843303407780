.checkboxContainer {
    margin: 15px 3px;
    display: flex;
    flex-direction: row;
}

.checkboxfield {
    height: 18px;
    width: 18px;
    border-radius: 2px;
    background-color: #FCFCFC;
    box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21);
    display: block !important;
}

.checkboxLabel {
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 20px;
    margin-top: -3px;
}