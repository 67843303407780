.scrolling-test {
    overflow: visible;
    height: 600px;
}

.scrolling-test::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.comment-full-container {
    display: flex;
    margin: 35px 32px 0px 32px;
    user-select: text;
}

.comment-full-container .comment-container {
    min-height: 110px;
    max-height: 110px;
    width: auto;
    border-radius: 4px;
    background-color: #FCFCFC;
    box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21);
    flex: 1 1;
    overflow-y: scroll;
}

.comment-full-container .comment-image {
    height: 64px;
    width: 64px;
    background-color: pink;
    border-radius: 50px;
    margin-right: 32px;
}

.comment-full-container .comment-content {
  flex: 1 1;
  display: flex;
  /* flex-direction: column;
  gap: 10px; */
  position: relative;
}

.comment-full-container .comment-container .comment-name-time {
    display: flex
}
            
.comment-full-container .comment-container .comment-name-time .comment-name {
    height: 40px;
    width: auto;
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    padding-top: 16px;
    padding-left: 16px;

}

.comment-full-container .comment-container .comment-name-time .comment-time {
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    padding-top: 18px;
    padding-left: 16px;
}

.comment-full-container .comment-container .comment-comment {
    height: auto;
    width: auto;
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    padding-top: 5px;
    padding-left: 16px;
    
}

.comment-full-container .comment-content .toggle-form {
  flex: 1 1;
  /* align-self: flex-end; */
  justify-items: flex-end;
  position: absolute;
  top: 10px;
  right: 25px;
}

.comment-full-container .comment-content .toggle-form button {
  text-transform: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  padding: 5px 10px;
}

.read-only-permissions-label {
  flex: 1 1;
  align-self: flex-end;
  justify-items: flex-end;
  position: absolute;
  top: 10px;
  right: 25px;
  text-transform: none;
  color: #1976d2;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

