.enable-step {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #1c6fa3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

i.fa {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #979797;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

i.fa.active-step {
  background: #1c6fa3;
}

i.fa.completed-step {
  background: #3d801c;
}

.step:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 112%;
  width: 2px;
  height: 41px;
  background: #979797;
  left: 13px;
}

.step {
  position: relative;
}
