.datePickerContainer {
    margin: 10px 3px
}

.datePickerLabel {
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}

.datePicker {
    height: 40px;
    border: 1px solid;
    border-radius: 4px;
    border-color: #fcfcfc;
    background-color: #FCFCFC;
    box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21);
}

input[type=date] {
    color: #4A4949;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding-left: 15px;
}