.footer {
    height: 40px;
    width: auto;
    background-color: #032033;
    text-align: center;

}



.footer-text {
    color: #ffffff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    padding-top: 14px;
    padding-bottom: 11px;
}