.top-header {
  grid-area: header;
  margin-top: 50px;
  margin-left: 30px;
}

.title-size {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #085280;
}

.subtitle-size {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 23px;
}

.button-footer {
  grid-area: footer;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-self: flex-end;
}

.flex-container {
  display: grid;
  grid-template-columns: 400px auto 0px;
  grid-template-rows: 140px;
  grid-template-areas:
    "sidebar header header ."
    "sidebar main main . "
    "sidebar footer . .";
}

.page-container {
  margin-top: 20px;
  grid-area: main;
  border-bottom: 1px solid gray;
  padding-bottom: 20px;
}

.stepper-container {
  grid-area: sidebar;
  justify-self: center;
  padding-top: 50px;
  border-right: 1px solid black;
  background-color: #dcdcdc;
}

.stepper {
  background-color: #dcdcdc !important;
}

.page-header {
  border-bottom: 1px solid gray;
  width: -webkit-fill-available;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
}

.attribute-header {
  padding-top: 20px;
  padding-left: 30px;
}

.page-attribute {
  width: -webkit-fill-available !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.page-date {
  /* border: 1px solid black!important; */
  padding-left: 30px !important;
}

.page-select {
  padding-left: 30px !important;
}

.page-select-dropdown {
  height: 56px;
  border-radius: 5%;
}

.radio-container {
  padding-left: 30px !important;
  display: flex;
  flex-direction: column;
}

.page-button {
  color: black !important;
  background-color: #fff !important;
}

.sub-attribute-header {
  padding-top: 20px;
}

.sub-radio-container {
  display: flex;
  flex-direction: column;
}

.policy-header {
  padding-top: 20px;
  padding-left: 30px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 23px;
}

.successful-header {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 25px;
  font-weight: bold;
  color: #085280;
}

.sub-successful-header {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
}

.successful-container {
  text-align: center;
  display: none;
}

.submit-container {
  text-align: center;
}

.submit-button {
  margin-top: 20px !important;
  background-color: #085280 !important;
  margin-left: 10px !important;
}

.green-check {
  color: greenyellow;
  font-size: 70px !important;
}

.accordion-top-container {
  margin-left: -48px;
}

.phase-container {
  margin-left: 50px;
}

.phase-title {
  font-weight: bold;
}

.phase-data-container {
  display: flex;
  flex-direction: row;
  margin: 15px;
}

.phase-data-sub-container {
  flex-basis: 24%;
}

.phase-data-sub-container-expiration {
  flex-basis: 25%;
}

.phase-data-sub-container-single-restriction {
  flex-basis: 25%;
  padding-left: 45px;
  display: "flex";
  flex-direction: "row";
  flex: 1;
  float: "right";
}

.phase-data-sub-container-expiration-dropdown {
  flex-basis: 25%;
}

.phase-data-title {
  font-weight: bold;
}

.attribute-select-dropdown {
  height: 30px !important;
  max-width: 100%;
  min-width: 140px;
}

.select-decision {
  max-width: 100%;
}

.datePickerContainer {
  margin: 0;
}

.confirm-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.confirm-btn {
  margin: 30px 20px !important;
}

.confirm-close-btn {
  border: 1px solid #1176b5 !important;
}
.info-icon {
  font-size: 15px;
  color: #ffffff;
  background: #0f5af0 !important;
  height: 20px !important;
  width: 20px !important;
}
.con-txt {
  font-weight: bold;
  margin-left: 10px;
  font-size: 24px;
  line-height: 15px;
}
.heading {
  color: #05314d;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
}
.sub-heading {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 30px;
  padding-bottom: 12px;
  font-weight: 400;
}

.section {
  padding: 24px 48px;
  background-color: white;
}
.section-heading {
  /* padding-bottom: 24px; */
  /* border-bottom: solid 1px #d2d2d2; */
}

.button {
  height: 40px;
  width: auto;
  padding: 0px 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.mendatory-sign {
  color: #ff0000;
}

.editor-class {
  border: 1px solid #dedede;
  border-radius: 2px;
  min-height: 200px;
  padding-left: 15px;
  max-height: 400px;
}
