.pdf-container {
  overflow: visible;
}

.card-row {
  position: unset;
  height: auto;
  width: -webkit-fill-available;
  border: 1px solid #dee1e2 !important;
  background-color: #ffffff !important;
  border-radius: 0px !important;
  margin-bottom: -7px;
  margin-right: 0px;
}

.card-row .accordion-body-card {
  min-height: 85px;
  width: auto;
  flex: auto;
  float: left;
  padding: 0px !important;
  position: unset;
  left: 146px;
  display: flex;
}

.card-row .accordion-body-card .card-project-name {
  max-height: 85px;
  max-width: 117px;
  width: 117px;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 51px;
  padding-top: 32px;
}

.card-row .accordion-body-card .card-status {
  max-height: 85px;
  max-width: 117px;
  width: 117px;
  color: #0ca024;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 33px;
  padding-top: 32px;
}
.card-row .accordion-body-card .card-address {
  max-height: 85px;
  max-width: 160px;
  width: 160px;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 28px;
  padding-top: 32px;
}
.card-row .accordion-body-card .card-city {
  max-height: 85px;
  max-width: 124px;
  width: 124px;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 27px;
  padding-top: 32px;
}
.card-row .accordion-body-card .card-state {
  max-height: 85px;
  max-width: 40px;
  width: 40px;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 16px;
  padding-top: 32px;
}
.card-row .accordion-body-card .card-projectid {
  max-height: 85px;
  max-width: 100px;
  width: 100px;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 48px;
  padding-top: 32px;
}
.card-row .accordion-body-card .card-hoaid {
  max-height: 85px;
  max-width: 105px;
  width: 105px;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 33px;
  padding-top: 32px;
}

.phase-container {
  overflow: visible;
  height: auto;
  max-height: 600px;
  margin-left: 0px!important;
}

.phase-container::-webkit-scrollbar {
width: 0px;
background: transparent; /* make scrollbar transparent */
}

.phase-container .phase-header {
height: 48px;
width: auto;
display: flex;
margin: 0px;
background-color: rgba(0,0,0,0.1);
}

.phase-container .phase-header .phase-header-name {
height: auto;
width: auto;
color: #2e2e2e;
font-family: 'Source Sans Pro', sans-serif;
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 24px;
flex: 0.18;
padding: 12px 0px 0px 24px;
}

.phase-body {
  height: auto;
  width: auto;
  margin: 0px;
}

.phase-type-container-1 {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.2px 1px 0.2px rgba(0, 0, 0, 0.3);
  padding: 0px 4px;
  width: fit-content;
}

.phase-count {
  color: #605b57;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  border-radius: 8px;
  background-color: #fcfcfc;
  box-shadow: 0 0.2px 1px 0.2px rgba(0, 0, 0, 0.3);
  padding: 0px 4px;
  width: fit-content;
}

.phase-types {
  color: #605b57;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  line-height: 16px;
  float: left;
}

.phase-type-name-1 {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  word-break: break-word;
}

.phase-list-values {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  word-break: break-word;
}

.restrictions-icon {
  height: 13px;
  margin-left: 2px;
}

.certification-expires {
  color: #605b57;
  font-family: "Source Sans Pro SemiBold";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  float: left;
}
