.pdf-container {
    overflow: visible;
    height: 600px;
}

.pdf-container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.pdf-container .pdf-header {
  height: 48px;
  width: auto;
  display: flex;
  margin: 17px 16px 0px 16px;
  background-color: rgba(17, 118, 181, 0.05);
}

.pdf-container .pdf-header .pdf-header-name {
  height: auto;
  width: auto;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  flex: 0.18;
  padding: 12px 0px 0px 24px;
}

.pdf-container .pdf-body {
  height: auto;
  width: auto;
  margin: 17px 16px 0px 16px;
}

.indy-pdf-container {
  display: flex;
  width: auto;
  border-bottom: 1px solid #edebe9;
  padding-bottom: 16px;
}

.indy-pdf-container .pdf-link-date-container {
  height: auto;
  width: 0px;
  padding: 12px 0px 0px 24px;
  flex: 0.18;
}

.indy-pdf-container .pdf-link-date-container .pdf-link {
  color: #007bff !important;
}

.indy-pdf-container .pdf-link-date-container .pdf-date-size {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
}

.indy-pdf-container .pdf-uploaded-by {
  height: auto;
  width: auto;
  padding: 12px 0px 0px 30px;
  flex: 0.2 1;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.indy-pdf-container .pdf-uploaded-date {
  height: auto;
  width: auto;
  padding: 12px 0px 0px 12px;
  flex: 0.2 1;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.indy-pdf-container .pdf-notes {
  height: auto;
  width: auto;
  padding: 12px 0px 0px 0px;
  flex: 0.18;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.indy-pdf-container .pdf-delete-button {
  color: #1176b5;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 12px 0px 0px 42px;
  flex: 0.06;
  height: fit-content;
  background-color: transparent;
  border-color: transparent;
}

.indy-pdf-container .pdf-delete-button:focus {
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  outline-offset: none !important;
}

.indy-pdf-container .pdf-delete-button:hover {
  background-color: transparent;
  border-color: transparent;
  color: #1176b5;
  outline: none !important;
  outline-offset: none !important;
}

.indy-pdf-container .pdf-delete-icon {
  color: #0c77ba;
  padding-right: 4px;
  margin-bottom:4px;
}

.delete-modal .delete-modal-body {
  text-align: center;
}

.delete-modal .delete-modal-cancel {
  background-color: grey;
}
.delete-modal .delete-modal-cancel:hover {
  background-color: grey;
}

.delete-modal .delete-modal-delete {
  background-color: red;
}
.delete-modal .delete-modal-delete:hover {
  background-color: red;
}

.individual-notes {
  top: 5px;
  right: 5px;
  padding: 5px;
  font-size: 23px;
  background-color: #fdfdfd;
  box-shadow: 0px 0px 2px 0px #aaa;
  cursor: pointer;
}
