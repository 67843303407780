.report-banner {
  background-image: linear-gradient(
      312.8deg,
      rgba(12, 123, 145, 0.2) 0%,
      rgba(8, 82, 128, 0.8) 100%
    ),
    url("../../images/GettyImages-1186126093.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 300px;
}
.report-body {
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 0px;
  min-height: 536px;
}

.fnma-connect-body {
  padding-top: 0px;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 0px;
}
.report-center {
  height: 60px;
  width: 413px;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 60px;
  padding-left: 110px;
  padding-top: 70px;
}
.report-desc {
  height: 36px;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 36px;
  padding-left: 110px;
  padding-top: 45px;
}

.report-card {
  height: 182px;
  box-shadow: 0px 1px 1px 1px rgba(121, 118, 118, 0.5);
  margin-right: 32px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 16px;
}
.report-card-body {
  margin: 40px 32px 45px;
}
.report-card-body-header {
  color: black;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5em;
  padding-left: 20px;
}
.report-body-content {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.125em !important;
  color: black;
  padding-left: 20px !important;
  width: 90%;
}
.report-text-body {
  min-height: 60px;
  height: 100%;
  font-size: 18px;
}
.download-btn {
  height: 40px;
  width: 124px;
  color: #1c6fa3;
  background-color: #fcfcfc;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 1px 0.8px 1px rgb(0 0 0 / 30%), 0 0.5px 0 1px rgb(0 0 0 / 20%);
}

.view-report-btn {
  height: 40px;
  width: 124px;
  color: #1c6fa3;
  background-color: #fcfcfc;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  border-radius: 4px;
  border: none;
  /*box-shadow: 0 1px 0.8px 1px rgb(0 0 0 / 30%), 0 0.5px 0 1px rgb(0 0 0 / 20%);*/
}

.report-body-btn {
  width: 10%;
  margin-top: -15px;
}
.report-card-content {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.report-disclosure {
  max-width: 40%;
  margin-left: auto;
  margin-right: 0;
  color: white;
  background: rgba(255, 255, 255, 0.25);
  padding: 10px;
  margin-top: 90px;
  margin-right: 20px;
  border-radius: 10px;
}
