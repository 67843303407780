.scrolling-test {
    overflow: visible;
    height: 600px;
}

.scrolling-test::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.lender-list-body {
    margin-top: 32px;
}

