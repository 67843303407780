.banner-alert {
  background: orange;
  text-align: center;
  height: fit-content;
  margin-bottom: 0 !important;
  z-index: 1;
  position: sticky !important;
  top: 0 !important;
  width: 100% !important;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 50% 50%;
}

.banner-announcement-alert {
  background: #666563;
  text-align: center;
  height: fit-content;
  margin-bottom: 0 !important;
  z-index: 1;
  position: sticky !important;
  top: 0 !important;
  width: 100% !important;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 50% 50%;
}
.alert-icon {
  grid-area: 1 / 1 / 2 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.announcement-info-icon {
  grid-area: 1 / 1 / 2 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-title {
  grid-area: 1 / 2 / 1 / 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -5px;
}
.alert-message {
  grid-area: 2 / 2 / 2 / 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.close-icon {
  grid-area: 1 / 3 / 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-alert {
  background: orange;
  text-align: center;
  height: fit-content;
  margin-bottom: 0 !important;
  z-index: 1;
  position: sticky !important;
  top: 0 !important;
  width: 100% !important;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 50% 50%;
}

.banner-announcement-alert {
  background: #666563;
  text-align: center;
  height: fit-content;
  margin-bottom: 0 !important;
  z-index: 1;
  position: sticky !important;
  top: 0 !important;
  width: 100% !important;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 50% 50%;
}
.announcement-icon {
  grid-area: 1 / 1 / 2 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.announcement-title {
  grid-area: 1 / 2 / 1 / 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -5px;
}
.announcement-message {
  grid-area: 2 / 2 / 2 / 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.announcement-link {
  text-decoration: underline !important;
  color: #ffffff;
}

.alert-link {
  text-decoration: underline !important;
}
