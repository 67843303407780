.textFieldLabel {
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}

.textFieldWrapper {
    box-sizing: border-box;
    height: 40px;
    /* border: 1px solid rgba(255,255,255,0); */
    border-radius: 4px;
}
.textFieldInput {  
    height: 40px;
    border: 1px solid;
    border-radius: 4px;
    border-color: #fcfcfc;
    background-color: #FCFCFC;
    box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.21);
}

.textFieldContainer {
    margin: 5px 3px;
}

input[type=text], select {
    color: #4A4949;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding-left: 15px;
}