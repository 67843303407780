.parent-form {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background-color: #f8f8f8;
}

.step-container {
  background-color: #f2f2f2;
}

.step {
  background-color: #f2f2f2 !important;
  margin: 70px 90px;
}
 
.field-container {
  display: flex;
  flex-direction: column;
}

.right-cancel-container {
  padding-top: 40px;
  width: 208px;
}

.top-title-container {
  padding: 49px 0px 0px 98px;
}

.field-lower-container {
  width: 900px;
  margin: 32px 0px 72px 98px;
  background-color: #ffffff;
}

.big-title {
  color: #05314d;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
}

.small-title {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 30px;
}

.section-title {
  color: #000f2b;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  padding: 32px 0px 16px 42px;
  border-bottom: 1px solid #e8e8e8;
}

.attribute-title {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding: 20px 48px 0px 48px;
}

.pers-reason-codes {
  width: 100%;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.40),
    0 0.5px 0 1px rgba(0, 0, 0, 0.40);

}

.attribute-input {
  padding: 0px 48px !important;
}

.bottom-button-container {
  margin-top: 24px;
  padding: 24px 48px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row-reverse;
}

.next-button {
  text-transform: none !important;
  background-color: #f2f2f2 !important;
  color: rgba(0, 0, 0, 0.82) !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  align-self: flex-end;
}

.next-btn-save {
  background-color: #1c6fa3 !important;
  color: #ffffff !important;
  margin-left: 15px !important;
}

.previous-button {
  text-transform: none !important;
  background-color: #f2f2f2 !important;
  color: rgba(0, 0, 0, 0.82) !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-right: 492px !important;
}

/*.review-preview {
  margin-right: 290px !important; 
}*/

.radio-box {
  padding-left: 38px !important;
  display: flex;
  flex-direction: column;
}

.table-header {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  text-align: center;
  margin-left: 0px;
  flex: 2 100%;
  background-color: #f2f2f2;
}

.table-header-title {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.table-header-title-2 {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  width: 180px;
}

.table-attributes {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  text-align: center;
  margin-left: 0px;
  flex: 2 100%;
}

.table-attributes-2 {
  display: flex;
  flex-flow: row;
  text-align: center;
  justify-content: space-around;
  margin-left: 0px;
  flex: 1 100%;
}

.table-attribute-4 {
  width: 150px !important;
  padding-top: 10px;
  margin-left: 25px;
}

.table-attribute {
  width: 120px !important;
  padding-top: 10px;
}

.table-attribute-1 {
  width: 120px !important;
  margin-left: 41px;
  padding-top: 10px;
}

.table-attribute-2 {
  width: 120px !important;
  margin-left: 62px;
  padding-top: 10px;
}

.table-attribute-3 {
  width: 120px !important;
  margin-left: 51px;
  padding-top: 10px;
}

.table-attribute-5 {
  width: 120px !important;
  margin-left: 73px;
  padding-top: 10px;
}

.table-attribute-6 {
  width: 120px !important;
  margin-left: 111px;
  padding-top: 10px;
}

.table-attribute-7 {
  width: 120px !important;
  margin-left: 26px;
  padding-top: 10px;
}

.table-radio {
  display: flex;
  flex-direction: row;
  width: 120px;
}

.table-radio-1 {
  display: flex;
  flex-direction: row;
  width: 120px;
  padding-left: 50px;
}

.trash-can {
  cursor: pointer;
  margin-left: 3px;
  padding-top: 13px;
}

.add-table-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.attribute-select {
  padding-left: 48px !important;
}

.attribute-select-dropdown {
  height: 40px;
  border-radius: 5%;
}

.select-decision {
  max-width: 100%;
}

.the-green-check {
  height: 64px !important;
  width: 64px !important;
  color: #5aca49;
  margin: 32px 0px 0px 411px !important;
}

.success-header {
  color: #05314d;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  padding: 0px 96px 8px 96px;
}

.sub-success-header {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
}

.success-button {
  text-transform: none !important;
  background-color: #1176b5 !important;
  color: #f2f2f2 !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 32px 0px 40px 365px !important;
}

.success-lower-container {
  width: 900px;
  margin: 32px 0px 450px 98px;
  background-color: #ffffff;
}

.cancel-pers-button {
  text-transform: none !important;
  background-color: #fcfcfc !important;
  color: rgba(0, 0, 0, 0.82) !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  max-height: 40px !important;
  left: -125%;
}

.cancel-pers-confirm-button {
  text-transform: none !important;
  background-color: #fcfcfc !important;
  color: rgba(0, 0, 0, 0.82) !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  max-height: 40px !important;
}

.go-back-button {
  text-transform: none !important;
  background-color: #fcfcfc !important;
  color: rgba(0, 0, 0, 0.82) !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.3),
    0 0.5px 0 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-right: 24px !important;
}

.confirm-btn {
  margin: 30px 20px !important;
}

.cancel-modal-button {
  /*text-transform: none !important;
  background-color: #1176b5 !important;
  color: #f2f2f2 !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;*/
  text-transform: none !important;
  background-color: #a62626 !important;
  color: #f2f2f2 !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.delete-modal-button {
  text-transform: none !important;
  background-color: #a62626 !important;
  color: #f2f2f2 !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.the-modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.82) !important;
}

.the-modal-title {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  padding-left: 5px !important;
}

.the-modal-body {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 10px 24px 24px 24px !important;
}

.the-modal-footer {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0px !important;
  justify-content: space-between;
  padding-left: 25px;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  fill: #3d801c;
}

.MuiStepIcon-root.MuiStepIcon-active {
  fill: #085280;
}

.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-5.MuiRadio-root.MuiRadio-colorSecondary.PrivateSwitchBase-checked-6.Mui-checked.MuiIconButton-colorSecondary {
  color: #085280;
}

/*.attribute-input-active{
    border: solid 1px red
}

input:focus{ outline: none; border:solid 1px green; }*/
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #80bdff !important;
  border-width: 2px !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #0c77ba !important;
}

.second-field-container {
    display: flex;
    flex-direction: column;
    width: calc(100% + 16px);
}

.pers-field-lower-container {
  margin: 32px 0px 72px 98px;
  background-color: #ffffff;
}

.pers-phase-container {
  padding: 20px 48px 0px 48px;
}


.pers-bottom-button-container {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
  flex-direction: row-reverse;
}

.reason-attribute-select {
  width: 75%;
  padding-left: 48px !important;
}
