.card-row {
  position: unset;
  height: auto;
  width: -webkit-fill-available;
  border: 1px solid #dee1e2 !important;
  background-color: #ffffff !important;
  border-radius: 0px !important;
  margin-bottom: -7px;
  margin-right: 0px;
}

.card-row .accordion-body-card {
  min-height: 85px;
  width: auto;
  flex: auto;
  float: left;
  padding: 0px !important;
  position: unset;
  left: 146px;
  display: flex;
}

.card-row .accordion-body-card .card-project-name {
  max-height: 85px;
  max-width: 117px;
  width: 117px;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  margin-left: 51px;
  padding-top: 32px;
}

.certification-list-values {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  word-break: break-word;
}

.card-certification-name-col {
  height: auto;
  width: auto;
  padding: 12px 0px 0px 25px;
  flex: 0.18 1;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.pdf-body {
  height: auto;
  width: auto;
  margin: 17px 16px 0px 16px;
}
