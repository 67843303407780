.banners-list-title {
  margin: 25px 0px 0px 130px;
  color: #05314d;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
}

.banner-list-nav {
  margin: 31px 130px 0px 130px;
}

.banner-list-nav .banner-list-link {
  padding: 0px 0px 11px 0px !important;
  margin-right: 32px;
  color: #1176b5;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.banner-list-nav .banner-list-link.active {
  padding: 0px 0px 11px 0px !important;
  color: #2e2e2e !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  background-color: transparent !important;
  border-bottom: 4px solid #ffb400;
  border-radius: 0px;
}

.banner-list-nav .add-banner {
  border-radius: 4px;
  border: 0px;
  background-color: #1176b5;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 10px 24px;
}

.banner-list-nav .add-banner:hover {
  border-radius: 4px;
  border: 0px;
  background-color: #1176b5;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 10px 24px;
}

.banner-list-nav .add-banner:focus {
  border-radius: 4px;
  border: 0px;
  background-color: #1176b5;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 10px 24px;
  outline: none !important;
  outline-offset: none !important;
}

.banner-list-nav .filter-container {
  height: 24px;
  width: 95px;
  padding-top: 10px;
  margin-right: 32px;
  cursor: pointer;
  padding-top: 10px;
  margin-right: 32px;
}

.banner-list-nav .filter-container .filter-button {
  height: 24px;
  width: 180px;
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  height: 24px;
  width: 180px;
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin-left: -52px;
  margin-top: -10px;
}

.banner-list-nav .filter-container .filter-icon {
  color: #1176b5;
  margin-right: 8px;
}

.card-container .banners-container {
  height: auto;
  width: 1180px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 39px;
  margin-right: 39px;
  margin-top: 26px;
  padding-top: 46px;
}

.banners-content {
  margin: 0px 130px 0px 130px;
  padding-bottom: 300px;
}

.banners-container .list-filters {
  display: flex;
  flex-direction: row;
  height: 48px;
  width: auto;
  background-color: rgba(0, 0, 0, 0.03);
  padding-top: 12px;
}
