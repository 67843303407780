.card-container {
    height: auto;
    width: 1180px;
    display: flex;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgba(121,118,118,0.5);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 147px;
    margin-top: 32px;
    padding-top: 0px;
    justify-content: end;
    position: relative;
}

.card-container .filter-container {
    margin-left: 39px;
    margin-top: 34px;
    height: 22px;
    width: auto;
    position: absolute;
    left: 0px;
    top: 0px;
}

.card-container .filter-container .filter-button {
    height: 20px;
    width: 70px;
    color: #1176B5;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}

.card-container .filter-container .filter-icon {
    height: 20px;
    width: 20px;
    color: #1176B5;
    margin-right: 8px;
}

.card-container .sorting-container {
    height: 24px;
    width: auto;
    display: flex;
    margin-top: 32px;
    margin-left: 779px;
    position: absolute;
    right: 0px;
    top: 0px;
}

.card-container .sorting-container .sort-by-text {
    height: 24px;
    width: auto;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    margin-right: 4px;
}

.card-container .sorting-container .sort-by-options {
    height: 24px;
    width: auto;
    color: #0D6DA9;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
}

.card-container .sorting-container .sort-by-caret {
    height: 16px;
    width: 16px;
    margin-bottom: 4px;
    margin-left: 5px;
}

.card-container .sorting-container .grid-toggle {
    margin-left: 52px;    
    margin-right: 38px;
}

.card-container .sorting-container .grid-toggle .sort-by-list {
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
    color: #1176B5;

}

.card-container .sorting-container .grid-toggle .sort-by-grid {
    height: 20px;
    width: 20px;
    margin-left: 16px;
    margin-bottom: 5px;
    color: #1176B5;

}

.card-container .projects-container {
    height: auto;
    width: 1180px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 39px;    
    margin-right: 39px; 
    margin-top: 26px;
    padding-top: 46px;    
}

.card-container .projects-container .list-filters {
    height: 48px;
    width: 1103px;
    background-color: rgba(0,0,0,0.03);
    padding-top: 12px;
}

.card-container .projects-container .list-filters .filter-by-condo {
    display: initial;
    height: 24px;
    width: 45px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 168px;
}

.card-container .projects-container .list-filters .filter-by-status {
    display: initial;
    height: 24px;
    width: 45px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 35px;
}

.card-container .projects-container .list-filters .filter-by-address {
    display: initial;
    height: 24px;
    width: 45px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 55px;
}

.card-container .projects-container .list-filters .filter-by-city {
    display: initial;
    height: 24px;
    width: 45px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 110px;
}

.card-container .projects-container .list-filters .filter-by-state {
    display: initial;
    height: 24px;
    width: 45px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 94px;
}

.card-container .projects-container .list-filters .filter-by-projectid {
    display: initial;
    height: 24px;
    width: 45px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 33px;
}

.card-container .projects-container .list-filters .filter-by-hoaid {
    display: initial;
    height: 24px;
    width: 45px;
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 46px;
}

/* .card-container .projects-container .list-filters .sort-icon {
    height: 14px;
    width: 16px;
    color: #0D6DA9;
    margin-left: 8px;
} */

.results .results-grid-container .sort-icon{
    height: 15px;
    width: 15px;
    color: #0D6DA9;
    margin-left: 8px;
}

.title-container {
    margin-top: 64px;
    margin-left: 76.5px;
}

.title-container .chevron-left {
    height: 32px;
    width: 32px;
    color: #1176B5;
    vertical-align: text-bottom;
    margin-right: 29px;

}

.title-container .search-results-text {
    height: 38px;
    width: 195px;
    display: inline-block;
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 38px;
    margin-right: 29px;
}

.title-container .search-results-subtext {
    height: 20px;
    width: 202px;
    display: inline-block;
    color: #ADA8A8;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}

.button-no-style {
    all: unset;
    cursor: pointer;
}

.button-no-style:focus {
    all: unset;
    cursor: pointer;
}
.formControl {
    min-width: 120px;
    margin: 2px;
}
.selectEmpty {
    margin-top: 2px;
}

.no-search-container {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.no-search-container .no-search-line {
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin-top: 20px;
}

.no-search-container .no-search-project {
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin-top: 20px;
}

.no-search-container .no-search-button {
    border-radius: 4px;
    background-color: #1176B5;
    border-color: transparent;
    box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D;
    padding: 8px 16px;
    color: #FFFFFF;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;
    margin-bottom: 38px;
    display: block;
}

.no-search-container .no-search-button:focus {
    background-color: #1176B5;
    border-color: transparent;
    outline: none !important;
    outline-offset: none !important;
}

.no-search-container .no-search-icon {
    height: 47.47px;
    width: 47.47px;
    color: #605B57;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

