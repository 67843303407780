.add-phase-button {
  height: 40px;
  width: auto;
  padding: 0px 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.table-add-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.profile-nav-button.highlight {
  font-weight: 700;
}
