.gridContainer {
    margin-bottom: 20px
}
.eligibilityStatus {
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin: 10px 0px
}
.dialoguClass {
    margin: 10px !important;
}

.please-contact-fanni {
    height: 36px;
    width: 100%;
    color: rgba(0,0,0,0.82);
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 18px;
  }

 .please-contact-fanni-border {
    box-sizing: border-box;
    height: 55px;
    width: 554px;
    border: 1px solid #FFE385;
    border-radius: 4px;
    background-color: #FFF2C7;
  }