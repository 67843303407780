.landing-container-background {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "search"
    "results";
}

.landing-container-background .search {
  grid-area: search;
  background-color: transparent;
  background-image: linear-gradient(
      312.8deg,
      rgba(12, 123, 145, 0.2) 0%,
      rgba(8, 82, 128, 0.8) 100%
    ),
    url("../../images/GettyImages-1185256981.png");
  background-size: 100% 680px;
  background-repeat: no-repeat;
  user-select: text;
}

.landing-container-background .search .title {
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 60px;
  margin: 67px 340px 24px 0px;
  margin-left: 20%;
  width: max-content;
}

.landing-container-background .search .search-grid-container {
  padding-bottom: 24px;
  width: 955px;
  border-radius: 16px;
  background-color: #ffffff;
  margin-bottom: 24px;
  margin-left: 20%;
}

.landing-container-background .search .search-grid-container .sub-title {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  margin: 0px 0px 0px 32px;
  padding-top: 24px;
  text-align: start;
}

.landing-container-background .search .search-grid-container .search-bar-grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "row1"
    "row2"
    "row3"
    "row4"
    "row5";
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-1 {
  grid-area: row1;
  display: flex;
  padding-top: 22px;
  padding-left: 37px;
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-1
  .project-id-field {
  height: 39px;
  width: 307px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2);
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-1
  .project-name-field {
  height: 39px;
  width: 537px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2);
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-2 {
  grid-area: row2;
  display: flex;
  padding-top: 24px;
  padding-left: 37px;
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-2
  .project-street-field {
  height: 39px;
  width: 307px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2);
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-2
  .project-city-field {
  height: 39px;
  width: 193px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2);
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-2
  .project-state-field {
  height: 39px;
  width: 78px;
  padding-left: 6.5px;
  border-radius: 4px;
  background-color: #fcfcfc;
  border: none;
  box-shadow: 0 0.5px 0 1px rgb(0 0 0 / 36%);
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-2
  .project-zip-field {
  height: 39px;
  width: 193px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2);
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-3 {
  grid-area: row3;
  display: flex;
  padding-top: 24px;
  padding-left: 37px;
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-3
  .project-review-field {
  height: 39px;
  width: 307px;
  padding-left: 6.5px;
  border-radius: 4px;
  text-align: center;
  background-color: #fcfcfc;
  border: none;
  box-shadow: 0 0.5px 0 1px rgb(0 0 0 / 36%);
  /* box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.3),
    0 0.5px 0 1px rgba(0, 0, 0, 0.2); */
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-3
  .project-certification-field {
  height: 39px;
  width: 308px;
  padding-left: 6.5px;
  border-radius: 4px;
  text-align: center;
  background-color: #fcfcfc;
  border: none;
  box-shadow: 0 0.5px 0 1px rgb(0 0 0 / 36%);
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-3
  .project-lender-field {
  height: 39px;
  width: 193px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2);
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-3
  .search-field-button-lender {
  border-radius: 4px;
  border: 0px;
  background-color: #1176b5;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 10px 24px;
  margin: 21px 0px 0px 0px;
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-3
  .search-clear-button-lender {
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  background-color: transparent;
  border: 0px;
  margin-right: 16px;
  margin-left: 28px;
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-4 {
  grid-area: row4;
  display: flex;
  justify-content: end;
  padding: 24px 37px 0px 37px;
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-4
  .search-field-button {
  border-radius: 4px;
  border: 0px;
  background-color: #1176b5;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 10px 24px;
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-4
  .search-clear-button {
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  background-color: transparent;
  border: 0px;
  margin-right: 16px;
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .row-5 {
  grid-area: row5;
  display: flex;
  justify-content: end;
  padding-right: 15px;
  color: red;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0.44px;
  line-height: 23px;
}

.landing-container-background
  .search
  .search-grid-container
  .search-bar-grid
  .field-search-label {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0.44px;
  line-height: 23px;
  text-align: start;
  padding-bottom: 4px;
}

.landing-container-background .results {
  grid-area: results;
  background-color: transparent;
  margin-bottom: 40px;
}

.landing-container-background .results .results-grid-container {
  height: auto;
  width: 1340px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(121, 118, 118, 0.5);
}

.landing-container-background .results .results-grid-container .sub-title {
  color: #04314d;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 50px;
  padding-top: 32px;
  padding-left: 76px;
  padding-right: 20px;
}

.landing-container-background .results .results-grid-container .sub-title2 {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.landing-container-background .results .results-grid-container .download-xlsx {
  margin-left: auto;
  margin-right: 78px;
  margin-top: 80px;
}

.landing-container-background
  .results
  .results-grid-container
  .download-xlsx
  .icon {
  height: 20.2px;
  width: 20.2px;
  color: #0c77ba;
}

.landing-container-background
  .results
  .results-grid-container
  .download-xlsx
  .button {
  height: auto;
  width: auto;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

.landing-container-background
  .results
  .results-grid-container
  .download-xlsx
  .button:hover {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

.landing-container-background
  .results
  .results-grid-container
  .download-xlsx
  .button:focus {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  outline-offset: none !important;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container {
  display: grid;
  grid-template-columns: 170px 200px 240px 140px 240px auto;
  grid-template-rows: auto;
  background-color: #f8f8f8;
  grid-template-areas: "sort1 sort2 sort3 sort4 sort5 sort6 sort7 sort8 sort9";
  margin: 19px 56px;
  padding: 13px 16px;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container
  .sort-1 {
  grid-area: sort1;
  max-width: 100%;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container
  .sort-2 {
  grid-area: sort2;
  max-width: 100%;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container
  .sort-3 {
  grid-area: sort3;
  max-width: 100%;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container
  .sort-4 {
  grid-area: sort4;
  max-width: 100%;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container
  .sort-5 {
  grid-area: sort5;
  max-width: 100%;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container
  .sort-6 {
  grid-area: sort6;
  max-width: 100%;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container
  .sort-7 {
  grid-area: sort7;
  max-width: 90%;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container
  .sort-8 {
  grid-area: sort8;
  max-width: 80%;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.landing-container-background
  .results
  .results-grid-container
  .sorting-container
  .sort-9 {
  grid-area: sort9;
  max-width: 100%;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container {
  display: grid;
  grid-template-columns: 170px 200px 240px 140px 240px auto;
  grid-template-rows: auto;
  border-radius: 4px 4px 0px 0px;
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  grid-template-areas: "col1 col2 col3 col4 col5 col6 col7 col8 col9";
  margin: 0px 56px;
  min-height: 146px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-1 {
  grid-area: col1;
  max-width: 100%;
  padding: 20px 10px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-1
  .type-image-container-est {
  height: 100%;
  width: 100%;
  background-image: url(../../images/card-est.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5px 0px 0px 5px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-1
  .type-image-container {
  height: 100%;
  width: 100%;
  background-image: url(../../images/card-new.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5px 0px 0px 5px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-1
  .type-title-container {
  border-radius: 28px;
  background-color: #098620;
  height: auto;
  width: auto;
  margin: 5px 5px 0px 0px;
  padding: 0px 5px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-1
  .type-title-container
  .type-title {
  height: 24px;
  width: auto;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: x-small;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-2 {
  grid-area: col2;
  max-width: 100%;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-2
  .id {
  color: #605b57;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 17px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-2
  .name {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 7px;
  min-height: 75px;
  word-break: break-word;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-2
  .phase {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 0.6px 3px 0 rgba(0, 0, 0, 0.2),
    0 0.3px 1.2px 0 rgba(0, 0, 0, 0.15);
  width: max-content;
  padding-right: 10px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-3 {
  grid-area: col3;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 100%;
  padding-top: 47px;
  word-break: break-word;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-4 {
  grid-area: col4;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 100%;
  padding-top: 47px;
  word-break: break-word;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-5 {
  grid-area: col5;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 100%;
  padding-top: 47px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-6 {
  grid-area: col6;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 100%;
  padding-top: 47px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-7 {
  grid-area: col7;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 100%;
  padding-top: 47px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-8 {
  grid-area: col8;
  max-width: 100%;
  padding-top: 47px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-8
  .status {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-8
  .date {
  color: #605b57;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

.landing-container-background
  .results
  .results-grid-container
  .indv-result-container
  .col-9 {
  grid-area: col9;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  max-width: 100%;
  padding-top: 47px;
}

.top-nav-container {
  display: flex;
  padding: 33px 60px 0px 0px;
}

.top-nav-container .top-page {
  display: none;
}

.top-nav-container .top-break {
  display: none;
}

.top-nav-container .top-next {
  display: block;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-left: 24px;
}
.top-nav-container .top-prev {
  display: block;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.top-nav-container .top-disabled {
  display: block;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.pagination-arrow {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 17px !important;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding-bottom: 2px;
  margin-right: 9px;
  margin-left: 8px;
}
.circle-pagination-arrow {
  color: #0c77ba;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 17px !important;
  letter-spacing: 0;
  line-height: 20px;
  width: 3em !important;
  height: 3em !important;
  text-align: center;
  padding: 12px;
  margin-right: 9px;
  margin-left: 8px;
  border-radius: 64px;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.12);
}
.bot-nav-container {
  display: flex;
  padding: 46px 60px 72px 0px;
  justify-content: center;
}

.bot-nav-container .bot-page {
  display: block;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-right: 8px;
  margin-left: 8px;
}

.bot-nav-container .bot-break {
  display: block;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-right: 8px;
  margin-left: 8px;
}

.bot-nav-container .bot-next {
  display: block;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-left: 24px;
}
.bot-nav-container .bot-prev {
  display: block;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.bot-nav-container .bot-disabled {
  display: block;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.bot-nav-container .bot-active {
  display: block;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.not-found-container {
  text-align: center;
  margin: 140px auto 187px auto;
  padding-bottom: 187px;
}

.not-found-container .icon {
  height: 80px;
  width: 80px;
  color: #121212;
}

.not-found-container .text {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.not-found-container .sub-text {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
}

.not-found-container .not-found-button {
  border-radius: 4px;
  border: 0px;
  background-color: #1176b5;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 10px 24px;
}

.apartment-logo {
  height: 80px;
  width: 80px;
  margin-top: 50px;
}

.find-recently-viewed {
  height: 24px;
  width: 325px;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.message-body {
  height: 56px;
  width: 373px;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  margin-bottom: 122px;
}
.sorting-container {
  position: sticky;
  top: 10px;
  z-index: 1;
}

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skip-main:focus,
a.skip-main:active {
  color: #fff;
  background-color: #000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid yellow;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

.table-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 200px);
  grid-column-gap: 50px;
  color: black;
  max-width: 300px;
  max-height: 300px;
  margin-block-start: auto;
}

.table-wrapper .row {
  grid-column: 2 / -1;
  grid-row-gap: 50px;
  grid-template-rows: repeat(5, 50px);
  text-align: center;
}

.table-wrapper .row .box {
  padding: 10px;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  border-bottom: solid 1px black;
}

.col1 {
  text-align: left;
  padding-left: 0px;
  column-width: 300px;
}

.col2 {
  text-align: center;
  width: 180px;
}

.col3 {
  text-align: center;
  width: 160px;
}

.col4 {
  text-align: center;
  width: 160px;
}

.col5 {
  text-align: center;
  width: 160px;
}

.noBottomBorder {
  border-bottom: none !important;
}

.wrap-text {
  margin-left: 26%;
  max-width: 670px;
}
.th {
  font-weight: bold;
}

.warning-label {
  vertical-align: text-top;
  margin-left: 27px;
}

.black-icon {
  color: black;
}

.phase-header-card {
  box-sizing: border-box;
  width: -webkit-fill-available;
  border-top: 0px !important;
  border-left: 1px solid #d1d1d1 !important;
  border-right: 1px solid #d1d1d1 !important;
  border-bottom: 1px solid #d1d1d1 !important;
  border-radius: 0px 0px 4px 4px !important;
  background-color: #f8f8f8 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: 0px;
  cursor: context-menu;
  user-select: text;
}

.phase-header-card .accordion-header-space {
  padding: 0px;
  height: auto;
  display: flex;
}

.phase-header-card .accordion-header-space .accordion-header-container {
  display: flex;
  flex-grow: 4;
}

.phase-header-card
  .accordion-header-space
  .accordion-header-container
  .accordion-header-title {
  height: 56px;
  width: auto;
  color: rgba(0, 0, 0, 0.82);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0px;
  margin-left: 10px;
  padding-top: 17px;
}

.phase-header-card
  .accordion-header-space
  .accordion-header-container
  .accordion-header-icon {
  height: auto;
  width: 44px;
  color: #0c77ba;
  padding-left: 23px;
}

.phase-header-card .accordion-header-space .upload-attachment-button {
  font-family: 'Source Sans Pro', sans-serif;
  height: 40px;
  width: 208px;
  margin: 8px 16px 8px 0px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.phase-header-card .accordion-header-space .add-phase-button {
  height: 40px;
  width: auto;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0px 10px;
  margin: 8px 16px 8px 0px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.phase-body-card {
  height: auto;
  width: auto;
  border: 0px solid #dee1e2;
  background-color: #ffffff;
  padding: 0px 0px 0px 0px !important;
  overflow: auto;
}

.search-clock {
  color: red;
  margin-right: 6px;
  margin-bottom: 1px;
  height: 24px !important;
  width: 24px !important;
}

.expire-date {
  padding-left: 28px;
}
