.reason-info-icon {
    display: inline-flex !important;
    margin-left: 10px !important;
    font-size: 15px !important;
    color: #ffffff !important;
    background: #0f5af0 !important;
    height: 20px !important;
    width: 20px !important;
    margin-bottom: 10px;
}

.reason-code-info-container {
    font-size: 14px;
    max-height: "max-content";
    font-family: "Source Sans Pro Regular";

    .reason-code-info-title {
        margin: 20px 10px 10px 20px;
        color: #05314d;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }

    .reason-code-table {
        width: "100%";
    }

    .reason-code-header {
        background-color: rgba(17, 118, 181, 0.05);
        margin: 17px 16px 0px 16px;
    }

    .reason-code-header-desc {
        color: #2e2e2e;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        flex: 1.18 1;
        padding: 12px 0px 0px 24px;
    }

    .reason-code-row {
        border-bottom: 1px solid rgb(237, 235, 233);
        padding-bottom: 16px;
    }

    .reason-code-row-desc {
        padding: 12px 0px 0px 25px;
        flex: 1.18 1;
        color: #2e2e2e;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
    }
}