.modal {
  z-index: 1111;
  display: none;
  overflow: hidden;
  background-color: #00000080;
}

.modal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.modal-body {
  padding: 0px !important;
}

.idle-content {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 20px;
}

.modal-dialog {
  /* max-width: 500px; */
  margin: 1.75rem auto;
  margin-top: 7.5%;
}

.content {
  height: auto;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.idle-button {
  height: 45px;
  width: 82px;
  border-radius: 4px;
  background-color: #1176b5;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d;
}
