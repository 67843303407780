.feed-card {
  display: flex;
  width: auto;
  border-bottom: 1px solid #edebe9;
  padding-bottom: 16px;
}

.feed-col-card {
  display: "flex";
  height: auto;
  width: auto;
  padding: 12px 0px 0px 25px;
  flex: 0.18 1;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.individual-close {
  margin-top: 5px;
  right: 135px;
  padding: 5px;
  font-size: 23px;
  background-color: #fdfdfd;
  box-shadow: 0px 0px 2px 0px #aaa;
  cursor: pointer;
}

.feed-button {
  margin-right: 35px;
  color: #1176b5;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 12px 0px 0px 42px;
  flex: 0.06;
  height: fit-content;
  background-color: transparent;
  border-color: transparent;
}

.feed-button:focus {
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  outline-offset: none !important;
}

.feed-button:hover {
  background-color: transparent;
  border-color: transparent;
  color: #1176b5;
  outline: none !important;
  outline-offset: none !important;
}

.feed-icon {
  color: #0c77ba;
  padding-right: 4px;
  margin-bottom: 4px;
}
