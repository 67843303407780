.site-selection {
  margin-left: 10px;
  margin-bottom: 10px;
}

.announcment-type {
  margin-left: 10px;
  width: 50%;
  float: left;
}

.create-announcement {
  display: grid;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 280px;
  inline-size: fit-content;
}

.sub-label {
  padding-left: 372px;
}

.sub-label-description {
  padding-left: 362px;
}

.text-box {
  min-width: 500px;
  max-width: 500px;
}

.description-text {
  min-width: 500px;
  max-width: 500px;
  min-height: 250px;
  max-height: 250px;
}
.report-radio {
  margin-left: 15px;
  margin-right: 5px;
}

.home-radio {
  margin-right: 5px;
}

.banner-page {
  overflow: hidden;
}

.close {
  font-size: 20px;
  color: red;
}

.add-icon {
  margin-left: -29px;
  margin-top: -3px;
  color: #007bff;
}

.intial-add-icon {
  color: #007bff;
}

