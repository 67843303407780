.field-info-container {
    margin-left: 42px;
    margin-bottom: 11px;
}

.radiobtn-letter-error {
    color: red;
    font-size: 14px;
    margin-left: 42px;

}

.letter-checkbox-container {
    margin-left: 42px;
}

.letter-accordion-body-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: 
        "left right"
}

.letter-accordion-body-container .left {
    grid-area: left;
    padding-top: 10px;
    padding-left: 10px;
}

.letter-accordion-body-container .right {
    grid-area: right;
    padding-top: 10px;
}

.letter-accordion-body-container .left .label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 1px;
}

.letter-accordion-body-container .left .value {
    font-size: 14px;
    padding-bottom: 8px;
    margin-bottom: 0;
}

.letter-accordion-body-container .right .label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 1px;
}

.letter-accordion-body-container .right .value {
    font-size: 14px;
    padding-bottom: 8px;
    margin-bottom: 0;
}

.decision-modal-parent-ineligible {
    margin-top: 25px;
    display: grid;
    grid-template-columns: max-content;
    grid-template-rows: auto;
    grid-template-areas: 
    "name header header date"
    "name rest rest rest"
    "address list list list"
    "address cond cond cond"
    "address cond cond cond"
}

.decision-modal-parent-ineligible .name {
    grid-area: name;
    margin-right: 20px;
    margin-left: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.decision-modal-parent-ineligible .decision {
    grid-area: header;
    margin-bottom: 25px;
}

.decision-modal-parent-ineligible .date {
    grid-area: date;
    margin-bottom: 25px;
}

.decision-modal-parent-ineligible .conditions {
    grid-area: cond;
    margin-right: 25px;
    margin-top: 12px;
}

.decision-modal-parent-ineligible .restrictions {
    grid-area: rest;
    margin-right: 25px;
    margin-top: 12px;
}

.decision-modal-parent-ineligible .list {
    grid-area: list;
    margin-right: 25px;
    margin-top: 12px;
}