.header-container {
  margin-top: 64px;
  margin-left: 78px;
  margin-right: 66px;
}

.header-container .cookie-dash {
  font-family: 'Source Sans Pro', sans-serif;
  margin-right: 5px;
}

.header-container .cookie-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  margin-left: 5px;
}

.cancel-button {
  border: none;
  margin-right: 20px;
  color: #1c6fa3;
}

.header-container .project-title {
  height: 38px;
  width: auto;
  display: inline-block;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin-right: 29px;
  margin-top: 5px;
}

.list-container {
  overflow: scroll;
  height: auto;
}

.list-container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.list-container .list-header {
  height: 48px;
  width: auto;
  display: flex;
  margin: 17px 16px 0px 16px;
  background-color: rgba(0, 0, 0, 0.03);
}

.list-container .list-header .list-header-name {
  height: auto;
  width: auto;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  flex: 0.3;
  padding: 12px 0px 0px 24px;
}

.list-container .list-body {
  height: auto;
  width: auto;
  margin: 17px 16px 0px 16px;
}

.card-row-list {
  display: flex;
  width: auto;
  border-bottom: 1px solid #edebe9;
  padding-bottom: 16px;
}

.card-name-list {
  height: auto;
  width: auto;
  padding: 12px 0px 0px 25px;
  flex: 0.3 1;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.table-header {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  margin: 17px 0px 0px 17px;
}

.pers-status-button {
  background-color: #1176B5;
  border-radius: 4px;
  box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border-color: #1176B5;
  border-style: none;
  padding: 8px 16px;
}

.pers-status-modal-header {
  padding: 22px 0px 12px 0px!important;
  margin: 0px 24px;
  border-bottom: 1px solid rgba(0,0,0,0.82)!important;
}

.pers-status-modal-header .pers-title-container {
  display: flex;
  flex-direction: row;
}

.pers-status-modal-header .pers-title-container .clock {
  height: auto!important;
  width: 16px!important;
}

.pers-status-modal-header .pers-title-container .title {
  color: rgba(0,0,0,0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  padding-left: 13px;
}

.pers-status-modal-body {
  margin: 0px 24px!important;
}

.pers-status-modal-body .title {
  color: #2E2E2E;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 16px;
}

.pers-status-modal-body .select {
}

.pers-status-modal-body .pers-error-message {
  color: #f44336;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 4px;
  font-size: 0.75rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.pers-status-modal-body .select-dropdown {
  height: 40px;
  border-radius: 4%;
  width: -webkit-fill-available;
}

.pers-status-modal-body .input {

}

.pers-status-modal-footer {
  margin-top: 24px!important;
  padding: 12px 24px!important;
  background-color: rgba(0,0,0,0.03)!important;
}

.pers-status-modal-footer .submit {
  background-color: #1176B5;
  border-radius: 4px;
  box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border-color: #1176B5;
  border-style: none;
  padding: 8px 16px;
}

.pers-status-modal-footer .submit:disabled {
  background-color: #1176B5;
  border-radius: 4px;
  box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D;
  color: #FFFFFF;
  opacity: 0.25;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border-color: #1176B5;
  border-style: none;
  padding: 8px 16px;
}

.pers-status-modal-footer .reset {
  background-color: #FCFCFC;
  border-radius: 4px;
  box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px rgba(0,0,0,0.82);;
  color: rgba(0,0,0,0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border-color: rgba(0,0,0,0.82);
  border-style: none;
  padding: 8px 16px;
}

.pers-title-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pers-dropdown-button {
  border-radius: 4px!important;
  background-color: #FCFCFC!important;
  box-shadow: 0 1px 0.8px 1px rgba(0,0,0,0.3), 0 0.5px 0 1px rgba(0,0,0,0.2);
  color: rgba(0,0,0,0.82)!important;
  font-family: "Source Sans Pro"!important;
  font-size: 16px!important;
  letter-spacing: 0!important;
  line-height: 24px!important;
  font-weight: 400!important;
  text-transform: capitalize!important;
}

.pers-dropdown-button:disabled {
  border-radius: 4px!important;
  background-color: #FCFCFC!important;
  box-shadow: 0 1px 0.8px 1px rgba(0,0,0,0.3), 0 0.5px 0 1px rgba(0,0,0,0.2);
  color: rgba(0,0,0,0.82)!important;
  opacity: 0.25;
  font-family: "Source Sans Pro"!important;
  font-size: 16px!important;
  letter-spacing: 0!important;
  line-height: 24px!important;
  font-weight: 400!important;
  text-transform: capitalize!important;
}

.pers-dropdown-button .description-icon{
  color: #0F5AF0;
  margin-right: 8px;
  margin-left: 8px;
}
.pers-dropdown-button .down-arrow {
  color: #0F5AF0;
  margin-left: 16px;
}

.dropdown-list-icon {
  min-width: 40px!important;
}

.show-modal {
  z-index: 1050!important;
}
.title-header-button-container {
  display: flex;
  flex-direction: row;
  height: max-content;
}

.title-header-button-container .download-record-button {
  background-color: #FCFCFC;;
  border-radius: 4px;
  box-shadow: 0 1px 0.8px 1px rgba(0,0,0,0.3), 0 0.5px 0 1px rgba(0,0,0,0.2);
  color: rgba(0,0,0,0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border-color: #FCFCFC;
  border-style: none;
  padding: 8px 16px;
}

.title-header-button-container .button-divider {
  width: 1px;
  border: 1px solid #979797;
  background-color: #D8D8D8;
  margin: 0px 24px;
}

.get-app-icon {
  color: #0F5AF0;
  margin-right: 6px;
}

.my-modal{
  display: flex!important;
  align-items: center!important;
  min-height: calc(100% - 1rem)!important;
}

.profile-nav-button.highlight {
  font-weight: 700;
}