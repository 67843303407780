.pdf-container{
    overflow: visible;
}
.indy-pdf-container .letter-body-header {
    height: auto;
    width: auto;
    padding: 12px 0px 0px 25px;
    flex: 0.18;
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}