.file-upload-btn {
  height: 40px;
  width: 82px;
  border-radius: 4px;
  background-color: #1176b5;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d;
}

.file-progressBar {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin: 0px 10px 5px 5px;
  vertical-align: top;
  flex: 6 !important;
  margin-left: 50px;
  box-sizing: content-box;
  width: calc(100% - 50px);
}

.file-progressBar div {
  height: auto;
  color: #fff;
  text-align: right;
  line-height: 15px;
  width: 0;
  background-color: #4caf50;
  border-radius: 3px;
  font-size: 13px;
}

.modal {
  z-index: 1111;
  display: none;
  overflow: hidden;
  background-color: #00000080;
}

.modal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.modal .modal-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.modal-image-text {
  position: absolute;
  color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.modal-footer-spacing {
  justify-content: flex-start !important;
}

.modal-backdrop {
  /* display: contents !important; */
}

.modal-body {
  padding: 0px !important;
}

.content {
  height: auto;
}

.content .name-input-container {
  padding: 1rem;
}

.content .name-input-container .name-title {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.content .name-input-container .name-input {
  height: 40px;
  width: -webkit-fill-available;
  border-width: 1px;
  border-radius: 4px;
  color: #4a4949;
  background-color: #fcfcfc;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding-left: 17px;
  margin-top: 10px;
}

.content .notes-input-container {
  margin: 0px;
  padding: 1rem;
}

.content .notes-input-container .notes-title {
  color: #2e2e2e;
  font-family: "Source Sans Pro Regular";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
}

.content .notes-input-container .notes-input {
  height: 40px;
  width: -webkit-fill-available;
  border-width: 1px;
  border-radius: 4px;
  color: #4a4949;
  background-color: #fcfcfc;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding-left: 17px;
  margin-top: 10px;
}

.content .content-container {
  margin: 0 px;
  height: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-height: 510px;
  overflow-y: auto;
}

.content .content-container .content-title {
  color: #2e2e2e;
  font-family: "Source Sans Pro Regular";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
}

.content .content-container .drop-container {
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: auto;
  height: 239px;
  border: 2px dashed #979797;
  background-color: #fcfcfc;
}

.content .content-container .drop-container .drop-message {
  text-align: center;
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
}

.content .content-container .drop-container .drop-message .upload-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 37.5px auto auto auto;
}

.content
  .content-container
  .drop-container
  .drop-message
  .upload-icon
  .upload-icon-md {
  height: 48.48px;
  width: 48.48px;
  color: #0c77ba;
}

.content .content-container .drop-container .drop-message .upload-text {
  height: auto;
  width: 137px;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 14px;
  text-align: center;
  margin: 17px auto auto auto;
  cursor: default;
}

.content .content-container .drop-container .drop-message .upload-text-button {
  height: auto;
  width: 137px;
  color: #1176b5;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 14px;
  text-align: center;
  margin: 17px auto auto auto;
  cursor: pointer;
}

.content .content-container .drop-container .file-input {
  display: none;
}

.content .content-container .file-display-container {
  margin-top: 20px;
  width: auto;
}

.content .content-container .file-display-container .file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.content .content-container .file-display-container .file-status-bar > div {
  overflow: hidden;
}

.content
  .content-container
  .file-display-container
  .file-status-bar
  .file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.content
  .content-container
  .file-display-container
  .file-status-bar
  .file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 60px;
  color: #4aa1f3;
}

.content
  .content-container
  .file-display-container
  .file-status-bar
  .file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: red;
}

.content
  .content-container
  .file-display-container
  .file-status-bar
  .file-error-message {
  color: red;
}

.content
  .content-container
  .file-display-container
  .file-status-bar
  .file-type-logo {
  width: 50px;
  height: 50px;
  background: url(../../images/generic.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
}

.content
  .content-container
  .file-display-container
  .file-status-bar
  .file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.content
  .content-container
  .file-display-container
  .file-status-bar
  .file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 12px;
  cursor: pointer;
  color: #d0021b;
  margin-right: 10px;
}

.file-status {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 12px;
  cursor: pointer;
  color: #444242;
  margin-right: 10px;
  font-size: 14px;
}

.content
  .content-container
  .file-display-container
  .file-status-bar
  .file-done {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 10px;
  cursor: pointer;
  color: #008719;
  margin-right: 10px;
}

.content .upload-modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.content .upload-modal .overlay {
  width: 100%;
  height: 89%;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  left: 0;
}

.content .upload-modal .progress-container {
  background: white;
  width: 500px;
  height: 119px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.content .upload-modal .progress-container span {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-left: 10px;
  font-size: 20px;
}

.content .upload-modal .progress-container .progress-close {
  float: right;
  padding-right: 9px;
}

.content .upload-modal .progress-container .progress {
  display: flex;
  height: 10px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin-right: 20px;
  margin-left: 58px;
  margin-top: 0px;
  height: 10px;
}

.content .upload-modal .progress-container .progress .progress-bar {
  position: absolute;
  background-color: #4aa1f3;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.content .upload-button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 33.5px;
  height: 64px;
  justify-content: space-between;
  padding: 12px;
  background-color: #f8f8f8;
  margin-bottom: 10px;
}

.content .upload-button-container .upload-button {
  /* position: sticky; */
  background-color: #1176b5;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  width: 160px;
}

.content .upload-button-container .close-button {
  background-color: #fcfcfc;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border-color: rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 0.4px 0.6px 0 rgb(0 0 0 / 37%), 0 0.1px 0 0.4px rgb(0 0 0 / 21%);
}

.progress {
  display: flex;
  height: 10px !important;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin-right: 20px;
  margin-left: 58px;
  margin-top: 0px;
}

.error {
  color: red;
  letter-spacing: 0.4px;
  line-height: 20px;
  font-size: 14px;
  padding-top: 0px !important;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

/*.upload-attachment-window{
  /*padding-top: 10%!important;
  /*margin-top: -30px!important;*/
/*transform: translate(0%, -5%)!important;

}*/
.modal-dialog {
  /* max-width: 500px; */
  margin: 1.75rem auto;
  margin-top: 7.5%;
}
