.pdf-container {
  overflow: visible;
  height: 600px;
}

.autoheight {
  height: auto !important;
}

.pdf-container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.pdf-container .pdf-header {
  height: 55px;
  width: auto;
  display: flex;
  margin: 17px 16px 0px 16px;
  background-color: rgba(0, 0, 0, 0.03);
}

.pdf-container .pdf-header .pdf-header-name {
  height: auto;
  width: auto;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  flex: 0.18;
  padding: 12px 0px 0px 24px;
}

.pdf-container .pdf-header .pdf-header-status {
  height: auto;
  width: auto;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  flex: 0.2 1;
  padding: 12px 0px 0px 24px;
}

.pdf-container .pdf-body {
  height: auto;
  width: auto;
  margin: 17px 16px 0px 16px;
}

.sort-icon {
  height: 14px;
  width: 10px;
  color: #0d6da9;
  margin-left: 8px;
}

.card-row-pers {
  display: flex;
  width: auto;
  border-bottom: 1px solid #edebe9;
  padding-bottom: 16px;
}

.card-project-name-pers {
  display: "flex";
  height: auto;
  width: auto;
  padding: 12px 0px 0px 25px;
  flex: 0.18 1;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
