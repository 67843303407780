.pdf-container{
    overflow:visible;
}
.decision-modal{
    z-index: 1050!important;
}

.decision-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
}

.decision-modal-parent {
    margin-top: 25px;
    display: grid;
    grid-template-columns: max-content;
    grid-template-rows: auto;
    grid-template-areas: 
    "name header header date"
    "name rest rest rest"
    "name rest rest rest"
    "name list list list"
    "name cond cond cond"
    "name cond cond cond"
}

.decision-modal-parent .name {
    grid-area: name;
    margin-right: 20px;
    margin-left: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.decision-modal-parent .decision {
    grid-area: header;
    margin-bottom: 25px;
}

.decision-modal-parent .date {
    grid-area: date;
    margin-bottom: 25px;
    margin-right: 15px;
}

.decision-modal-parent .conditions {
    margin-right: 25px
}

.decision-modal-parent .pers-reason {
    grid-area: cond;
    margin-right: 25px
}

.decision-modal-parent .restrictions {
    grid-area: rest;
    margin-right: 25px
}

.indy-pdf-container .decision-phase-header {
    height: auto;
    width: auto;
    padding: 12px 0px 0px 25px;
    flex: 0.18;
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}

.indy-pdf-container .decision-reviewed-header {
    height: auto;
    width: auto;
    padding: 12px 0px 0px 25px;
    flex: 0.18;
    color: #2E2E2E;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}

