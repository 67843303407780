.top-header {
    grid-area: header;
    margin-top: 50px;
    margin-left: 30px;
}



.title-size {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #085280;
}

.subtitle-size {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 23px;
}

.button-footer {
    grid-area: footer;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-self: flex-end;
}

.flex-container {
    display: grid;
    grid-template-columns: 400px auto 0px;
    grid-template-rows: 140px;
    grid-template-areas: 
        "sidebar header header ."
        "sidebar main main . "
        "sidebar footer . .";
}

.page-container {
    margin-top: 20px;
    grid-area: main;
    border-bottom: 1px solid gray;
    padding-bottom: 20px;
} 

.stepper-container {
    grid-area: sidebar;
    justify-self: center;
    padding-top: 50px;
    border-right: 1px solid black;
    background-color: #DCDCDC;
}

.stepper {
    background-color: #DCDCDC!important;
}

.page-header{
    border-bottom: 1px solid gray;
    width: -webkit-fill-available;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
}

.attribute-header {
    padding-top: 20px;
    padding-left: 30px;
}

.page-attribute {
    width: -webkit-fill-available!important;
    padding-left: 30px!important;
    padding-right: 30px!important;
}

.page-date {
    /* border: 1px solid black!important; */
     padding-left: 30px!important;
 }



.page-select {
    padding-left: 30px!important;
}

.page-select-dropdown {
    height: 56px;
    border-radius: 5%;
}

.radio-container {
    padding-left: 30px!important;
    display: flex;
    flex-direction: column;
}


.page-button {
    color: black!important;
    background-color: #fff!important;
} 

.sub-attribute-header {
    padding-top: 20px;
}

.sub-radio-container {
    display: flex;
    flex-direction: column;
}

.policy-header {
    padding-top: 20px;
    padding-left: 30px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 23px;
}

.successful-header {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #085280;
}

.sub-successful-header {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 15px;
}

.successful-container {
    text-align: center;
    display: none;
}

.submit-container {
    text-align: center;
}

.submit-button {
    margin-top: 20px!important;
    background-color: #085280!important;
    margin-left: 10px!important;
}

.green-check {
    color: greenyellow;
    font-size: 70px!important;
}

.accordion-top-container {
    margin-left: -48px;
}

.phase-container {
    margin-left: 50px;
}

.phase-title {
    font-weight: bold
}

.phase-data-container {
    display: flex;
    flex-direction: row;
    margin: 15px;
}

.phase-data-sub-container {
    flex-basis: 24%;
}

.phase-data-row-container {
    flex-basis: 80%;
    margin-left: 17%;
    margin-top: -20px;
}

.phase-data-title {
    font-weight: bold;
    padding-right: 10px;
}

.attribute-select-dropdown {
    height: 30px !important;
    max-width: 175px;
    min-width: 140px;
}

.select-decision {
    max-width: 100%;
}

.datePickerContainer {
    margin: 0;
}

.confirm-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center
}

.confirm-btn {
    margin: 30px 20px !important;
}

.confirm-close-btn {
    border: 1px solid #1176B5 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    fill: #3d801c;
}

.MuiStepIcon-root.MuiStepIcon-active {
    fill: #085280;
}

.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-5.MuiRadio-root.MuiRadio-colorSecondary.PrivateSwitchBase-checked-6.Mui-checked.MuiIconButton-colorSecondary{
    color: #085280;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #80bdff!important;
    border-width: 2px!important;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #0c77ba!important;
}