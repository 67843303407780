.banner-container {
  overflow: visible;
  height: 600px;
}

.banner-container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.banner-container .banner-header {
  height: 48px;
  width: auto;
  display: flex;
  margin: 17px 16px 0px 16px;
  background-color: rgba(17, 118, 181, 0.05);
}

.banner-container .banner-header .banner-header-name {
  height: auto;
  width: auto;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  flex: 0.4 1;
  padding: 12px 0px 0px 24px;
}

.banner-container .banner-header .banner-header-desc {
  height: auto;
  width: auto;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  flex: 1.18;
  padding: 12px 0px 0px 24px;
}

.banner-container .banner-body {
  height: auto;
  width: auto;
  margin: 17px 16px 0px 16px;
}

.card-row-banner {
  display: flex;
  width: auto;
  border-bottom: 1px solid #edebe9;
  padding-bottom: 16px;
}

.card-banner-desc {
  display: "flex";
  height: auto;
  width: auto;
  padding: 12px 0px 0px 25px;
  flex: 1.18 1;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.card-row-banner {
  display: flex;
  width: auto;
  border-bottom: 1px solid #edebe9;
  padding-bottom: 16px;
}

.card-banner-name {
  display: "flex";
  height: auto;
  width: auto;
  padding: 12px 0px 0px 25px;
  flex: 0.4 1;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.edit-button {
  margin-left: 15px;
  margin-top: -10px;
  color: #1176b5;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 12px 0px 0px 42px;
  flex: 0.06;
  height: fit-content;
  background-color: transparent;
  border-color: transparent;
}

.edit-button:focus {
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  outline-offset: none !important;
}

.edit-button:hover {
  background-color: transparent;
  border-color: transparent;
  color: #1176b5;
  outline: none !important;
  outline-offset: none !important;
}

.edit-icon {
  color: #0c77ba;
  padding-right: 4px;
  margin-bottom: 4px;
}
