.child-phase-id-title {
  height: 23px;
  width: auto;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
}

.child-phase-title {
  height: 38px;
  width: auto;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
}

.child-image-pill {
  border-radius: 28px;
  background-color: #0d6da9;
  margin: 16px 0px 0px 16px;
  position: absolute;
  float: left;
  /*width: 17vw;*/
}

.conditions-restrictions {
  margin-top: 35px;
}

.restrictions-conditions-parent {
  box-sizing: border-box;
  height: 250px;
  width: auto;
  border: 1px solid #0e51d8;
  border-radius: 4px;
  background-color: #fefbfb;
  box-shadow: 0 0.1px 0.2px 0 rgb(0 0 0 / 50%);
  overflow: auto;
}
